<template>
  <b-row>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group
        class="mb-0"
      >
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    />
    <b-col
      md="6"
      class="my-1"
    >
      <b-form-group
        label="Filter"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >

        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
	BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

export default {
	components: {
		BTable,
		BBadge,
		BRow,
		BCol,
		BFormGroup,
		BFormSelect,
		BPagination,
		BInputGroup,
		BFormInput,
		BInputGroupAppend,
		BButton,
	},
	data() {
		return {
			perPage: 5,
			pageOptions: [3, 5, 10],
			totalRows: 1,
			currentPage: 1,
			filter: null,
			filterOn: [],
			infoModal: {
				id: 'info-modal',
				title: '',
				content: '',
			},
			fields: [
				{
					key: 'id', label: 'Id',
				},
				{ key: 'name', label: 'Nombre de la sede' },
				{ key: 'code', label: 'Código habitacional' },
				{ key: 'type', label: 'Tipo de Sede' },
			],
			items: [
				{
					id: 1,
					// eslint-disable-next-line global-require
					name: 'Clinica Crecer calle 29 # 38 - 20',
					code: '404',
					type: 'Sede principal',
				},
				{
					id: 2,
					// eslint-disable-next-line global-require
					name: 'Clinica Crecer calle 38 # 29 - 18',
					code: '403',
					type: 'Sede Secundaria',
				},
			],
			status: [{
				1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
			},
				{
					1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
				}],
		}
	},
	computed: {
	},
	mounted() {
		// Set the initial number of items
		this.totalRows = this.items.length
	},
	methods: {
		info(item, index, button) {
			this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
